@import '../core/variables';

.builder                                     { background-color: #ffffff;
    .nav-builder-tabs                        { border: 0px;
        .nav-link:hover                      { border: 1px solid transparent; }
        .nav-link.active                     { border: 1px solid transparent; border-bottom: 5px solid $amber-700; color: $amber-700; opacity: 1.0; background-color: transparent; }
        .nav-link.active .icon-bg-stack      { color: $amber-700; opacity: 0.6; }
        .nav-link.active .icon-bg            { color: #ffffff; }
        .nav-link                            { color: $blue-700; font-size: 0.95rem; font-weight: bold; opacity: 0.6; }
    } 

    .nav-link.active:hover .icon-bg-stack    { opacity: 1.0 !important; }
}

