@import '../core/variables';

.design-box                                  { background-color: #ffffff;
    .nav-design-tabs                         {
        .nav-link:hover                      { border: 1px solid transparent; }
        .nav-link.active                     { border: 1px solid transparent; border-bottom: 5px solid $gray-700; color: $gray-700; opacity: 1.0; background-color: transparent; }
        .nav-link.active .icon-bg-stack      { color: $gray-700; opacity: 0.6; }
        .nav-link.active .icon-bg            { color: #ffffff; }
        .nav-link                            { color: $gray-500; font-size: 1.7rem; font-weight: bold; opacity: 0.6; }
    } 

    .nav-link.active:hover .icon-bg-stack    { opacity: 1.0 !important; }
}