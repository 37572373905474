/* =============================
	Variables
============================= */

/* Application Primary colors */
$blue-50:               #E3F2FD;
$blue-100:              #BBDEFB;
$blue-200:              #90CAF9;
$blue-300:              #64B5F6;
$blue-400:              #42A5F5;
$blue-500:              #2196F3;
$blue-600:              #1E88E5;
$blue-700:              #1976D2;
$blue-800:              #1565C0;
$blue-900:              #0D47A1;
$blue-A100:             #82B1FF;
$blue-A200:             #448AFF;
$blue-A400:             #2979FF;
$blue-A700:             #2962FF;

/* Application Gray Colors */
$gray-50:               #FAFAFA;
$gray-100:              #F5F5F5;
$gray-200:              #EEEEEE;
$gray-300:              #E0E0E0;
$gray-400:              #BDBDBD;
$gray-500:              #9E9E9E;
$gray-600:              #757575;
$gray-700:              #616161;
$gray-800:              #424242;
$gray-900:              #212121;

/* Application Accent Colors */
$amber-50:              #FFF8E1;
$amber-100:             #FFECB3;
$amber-200:             #FFE082;
$amber-300:             #FFD54F;
$amber-400:             #FFCA28;
$amber-500:             #FFC107;
$amber-600:             #FFB300;
$amber-700:             #FFA000;
$amber-800:             #FF8F00;
$amber-900:             #FF6F00;
$amber-A100:            #FFE57F;
$amber-A200:            #FFD740;
$amber-A400:            #FFC400;
$amber-A700:            #FFAB00;

/* Remaining Primary Colors */
$red-50:              #FFEBEE;
$red-100:             #FFCDD2;
$red-200:             #EF9A9A;
$red-300:             #E57373;
$red-400:             #EF5350;
$red-500:             #F44336;
$red-600:             #E53935;
$red-700:             #D32F2F;
$red-800:             #C62828;
$red-900:             #B71C1C;
$red-A100:            #FF8A80;
$red-A200:            #FF5252;
$red-A400:            #FF1744;
$red-A700:            #D50000;