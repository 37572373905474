.main-iphone {
    margin-top: 2em;
}

.main-iphone h2 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-align: center;
}

.main-iphone img {
    width: 100%;
}

.main-iphone .img-container {
    margin: 0.5em;
}

.main-iphone .txt-container {
    margin: 0.5em;
}