@import '../core/variables';

.palette-row { height: 6rem; }

.r-50 { background-color: $red-50; }
.r-100 { background-color: $red-100; }
.r-200 { background-color: $red-200; }
.r-300 { background-color: $red-300; }
.r-400 { background-color: $red-400; }
.r-500 { background-color: $red-500; }
.r-600 { background-color: $red-600; }
.r-700 { background-color: $red-700; }
.r-800 { background-color: $red-800; }
.r-900 { background-color: $red-900; }
.r-A100 { background-color: $red-A100; }
.r-A200 { background-color: $red-A200; }
.r-A400 { background-color: $red-A400; }
.r-A700 { background-color: $red-A700; }

.future-color-1 { background-color: #F4F8ED; }
.future-color-2 { background-color: #7271A6; }
.future-color-3 { background-color: #DBB79C; }
.future-color-4 { background-color: #BD3C65; }
.future-color-5 { background-color: #261B31; }

.sleak-color-1 { background-color: #FBF9F4; }
.sleak-color-2 { background-color: #D8E3F6; }
.sleak-color-3 { background-color: #7BC3EE; }
.sleak-color-4 { background-color: #B9B2A9; }
.sleak-color-5 { background-color: #6B91C2; }