.phone {
    float: right;
}

.sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0 !important;
}

iframe {
    height: 100%;
    width: 100%;
    border: 0px;
}